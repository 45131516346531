import React, { useContext, useState } from 'react';
import { Web3Context } from '../contexts/Web3Context';
import telegramIcon from '../images/telegram.svg';
import twitterIcon from '../images/twitter.svg';
import discordIcon from '../images/pinksale.png';
import logo from '../images/6.png';
const Navbar = () => {
  const { account } = useContext(Web3Context);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-gray-900 p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <img src={logo} className="w-10 h-10 mr-4" style={{ width:"150px", height: "50px" }} alt="PlasmaPulse Logo" />

        <div className="hidden md:flex items-center space-x-4">
          <a href="https://t.me/PlasmaPulseOfficial" className="hover:underline text-sm text-gray-400 flex items-center">
            <img src={telegramIcon} alt="Telegram" className="w-6 h-6 mr-1" />
            Telegram
          </a>
          <a href="https://x.com/PlasmaPulseEco" className="hover:underline text-sm text-gray-400 flex items-center">
            <img src={twitterIcon} alt="Twitter" className="w-6 h-6 mr-1" />
            Twitter
          </a>
      {/*    <a href="https://www.pinksale.finance/launchpad/pulsechain/0x47E29ad9f26890C8A070DD5E490Fce63af5408A0" className="hover:underline text-sm text-gray-400 flex items-center">
            <img src={discordIcon} alt="Pinksale" className="w-6 h-6 mr-1" />
            Presale
          </a> */}
        </div>
        <div className="hidden md:block">
          {account ? (
            <span className="text-white bg-purple-700 hover:bg-purple-800 transition duration-300 rounded p-2 text-sm cursor-pointer">
              Connected: {account.substring(0, 6)}...{account.substring(account.length - 4)}
            </span>
          ) : (
            <button className="bg-purple-700 hover:bg-purple-800 transition duration-300 text-white p-2 rounded">
              Connect Wallet
            </button>
          )}
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-400 hover:text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="https://t.me/PlasmaPulseOfficial" className="block text-sm text-gray-400 hover:underline flex items-center">
              <img src={telegramIcon} alt="Telegram" className="w-6 h-6 mr-1" />
              Telegram
            </a>
            <a href="https://x.com/PlasmaPulseEco" className="block text-sm text-gray-400 hover:underline flex items-center">
              <img src={twitterIcon} alt="Twitter" className="w-6 h-6 mr-1" />
              Twitter
            </a>
         {/*    <a href="https://www.pinksale.finance/launchpad/pulsechain/0x47E29ad9f26890C8A070DD5E490Fce63af5408A0" className="hover:underline text-sm text-gray-400 flex items-center">
            <img src={discordIcon} alt="Pinksale" className="w-6 h-6 mr-1" />
            Presale
          </a> */}
          </div>
          <div className="px-2 pt-2 pb-3">
            {account ? (
              <span className="block text-white bg-purple-700 hover:bg-purple-800 transition duration-300 rounded p-2 text-sm cursor-pointer">
                Connected: {account.substring(0, 6)}...{account.substring(account.length - 4)}
              </span>
            ) : (
              <button className="block bg-purple-700 hover:bg-purple-800 transition duration-300 text-white p-2 rounded w-full">
                Connect Wallet
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
