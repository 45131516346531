import React from 'react';
import Navbar from './components/Navbar';
import Pools from './components/Pools';
import Footer from './components/Footer';
import './App.css'; // Ensure this path points to your CSS file

function App() {
  return (
    <div className="min-h-screen relative">
      <div className="background"></div>
      <div className="overlay">
        <Navbar />
        <div className="container mx-auto p-4 flex-grow">
          <Pools />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
