import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Web3Context } from '../contexts/Web3Context';
import MasterChefABI from '../abis/MasterChef.json';
import ERC20ABI from '../abis/ERC20.json';
import LPTokenABI from '../abis/LPToken.json';
import BigNumber from 'bignumber.js';

const masterChefAddress = '0xC7Cb86f4F1E7690d2e0bE2Ea737c916a72156C2e';
const REWARDS_PER_BLOCK = 250;
const BLOCKS_PER_DAY = 6500;

const Pool = ({ pool, index, totalAllocPoints, logoUrl0, logoUrl1, poolLink, poolAddress, dex, version }) => {
  const { web3, account } = useContext(Web3Context);
  const [amount, setAmount] = useState('');
  const [userInfo, setUserInfo] = useState({ amount: '0', rewardDebt: '0' });
  const [pendingReward, setPendingReward] = useState('0');
  const [lpTokenBalance, setLpTokenBalance] = useState('0');
  const [token0Details, setToken0Details] = useState({});
  const [token1Details, setToken1Details] = useState({});
  const [totalStaked, setTotalStaked] = useState('0');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [farmName, setFarmName] = useState('');
  const [tokensPerDay, setTokensPerDay] = useState('0');
  const [apy, setApy] = useState('0.00');
  const [apr, setApr] = useState('0.00');
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    if (!web3 || !account) return;

    const masterChef = new web3.eth.Contract(MasterChefABI, masterChefAddress);
    const lpToken = new web3.eth.Contract(LPTokenABI, pool.lpToken);

    try {
      const user = await masterChef.methods.userInfo(index, account).call();
      const pending = await masterChef.methods.pendingToken(index, account).call();
      const balance = await lpToken.methods.balanceOf(account).call();
      const totalPoolBalance = await lpToken.methods.balanceOf(masterChefAddress).call();

      setUserInfo(user);
      setPendingReward(pending);
      setLpTokenBalance(balance);
      setTotalStaked(totalPoolBalance);

      const token0Address = await lpToken.methods.token0().call();
      const token1Address = await lpToken.methods.token1().call();

      const token0 = new web3.eth.Contract(ERC20ABI, token0Address);
      const token1 = new web3.eth.Contract(ERC20ABI, token1Address);

      const token0Name = await token0.methods.name().call();
      const token0Symbol = await token0.methods.symbol().call();
      const token1Name = await token1.methods.name().call();
      const token1Symbol = await token1.methods.symbol().call();

      setToken0Details({ address: token0Address, name: token0Name, symbol: token0Symbol });
      setToken1Details({ address: token1Address, name: token1Name, symbol: token1Symbol });
      setFarmName(`${token0Symbol}-${token1Symbol}`);

      const blocksPerDay = new BigNumber(BLOCKS_PER_DAY);
      const rewardPerDay = new BigNumber(REWARDS_PER_BLOCK).times(blocksPerDay);
      const poolAllocPoint = new BigNumber(pool.allocPoint);
      const totalAllocPointsBN = new BigNumber(totalAllocPoints);

      const poolRewardsPerDay = rewardPerDay.times(poolAllocPoint).div(totalAllocPointsBN);
      const userStakedAmount = new BigNumber(web3.utils.fromWei(user.amount, 'ether'));
      const userTokensPerDay = poolRewardsPerDay.times(userStakedAmount).div(new BigNumber(web3.utils.fromWei(totalPoolBalance, 'ether'))).toFixed(2);

      setTokensPerDay(userTokensPerDay);
      const rewardTokenPrice = 0.1;
      const lpTokenPrice = 1;

      const dailyRewardsInUSD = poolRewardsPerDay.times(rewardTokenPrice);
      const totalStakedInUSD = new BigNumber(web3.utils.fromWei(totalPoolBalance, 'ether')).times(lpTokenPrice);

      const aprValue = dailyRewardsInUSD.div(totalStakedInUSD).times(365).times(100).toFixed(2);
      const apyValue = ((Math.pow(1 + dailyRewardsInUSD.div(totalStakedInUSD).toNumber() / 365, 365) - 1) * 100).toFixed(2);

      setApr(aprValue);
      setApy(apyValue);

    } catch (error) {
      console.error('Error fetching user info or token details:', error);
    }
  }, [web3, account, pool.lpToken, pool.allocPoint, index, totalAllocPoints]);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  const handleMax = () => {
    setAmount(web3.utils.fromWei(lpTokenBalance, 'ether'));
  };

  const handleMaxLiquidity = () => {
    setAmount(web3.utils.fromWei(userInfo.amount, 'ether'));
  };

  const handleDeposit = async () => {
    if (!amount || parseFloat(amount) <= 0) {
      setMessage('Please enter a valid amount to deposit.');
      return;
    }

    const masterChef = new web3.eth.Contract(MasterChefABI, masterChefAddress);
    const lpToken = new web3.eth.Contract(ERC20ABI, pool.lpToken);

    setLoadingDeposit(true);
    setMessage('');
    try {
      await lpToken.methods.approve(masterChefAddress, web3.utils.toWei(amount, 'ether')).send({ from: account });
      await masterChef.methods.deposit(index, web3.utils.toWei(amount, 'ether')).send({ from: account });
      setMessage('Deposit successful!');
      fetchUserInfo();
    } catch (error) {
      setMessage('Deposit failed. Please try again.');
    } finally {
      setLoadingDeposit(false);
    }
  };

  const handleWithdraw = async () => {
    if (!amount || parseFloat(amount) <= 0) {
      setMessage('Please enter a valid amount to withdraw.');
      return;
    }

    const masterChef = new web3.eth.Contract(MasterChefABI, masterChefAddress);

    setLoadingWithdraw(true);
    setMessage('');
    try {
      await masterChef.methods.withdraw(index, web3.utils.toWei(amount, 'ether')).send({ from: account });
      setMessage('Withdraw successful!');
      fetchUserInfo();
    } catch (error) {
      setMessage('Withdraw failed. Please try again.');
    } finally {
      setLoadingWithdraw(false);
    }
  };

  const handleClaimRewards = async () => {
    const masterChef = new web3.eth.Contract(MasterChefABI, masterChefAddress);

    setLoadingClaim(true);
    setMessage('');
    try {
      await masterChef.methods.withdraw(index, '0').send({ from: account });
      setMessage('Rewards claimed successfully!');
      fetchUserInfo();
    } catch (error) {
      setMessage('Claim rewards failed. Please try again.');
    } finally {
      setLoadingClaim(false);
    }
  };

  return (
    <div className="bg-gray-900 p-4 mb-4 rounded-lg shadow-lg max-w-md md:max-w-lg lg:max-w-xl mx-auto border border-purple-600">
      <div className="flex flex-col md:flex-row justify-between items-center mb-0">
        <div className="flex items-center space-x-2">
          <h3 className="text-lg md:text-xl font-bold text-white">{farmName}</h3>
          <img src={logoUrl0} alt={`${token0Details.symbol} logo`} onError={(e) => { e.target.src = 'default.png'; }} className="w-6 h-6 md:w-8 md:h-8" style={{ marginLeft: '0px' }} />
          <img src={logoUrl1} alt={`${token1Details.symbol} logo`} onError={(e) => { e.target.src = 'default.png'; }} className="w-5 h-5 md:w-6 md:h-6" style={{ marginLeft: '-10px' }} />
          <span className="text-gray-400 font-bold text-xs md:text-base">{dex}  </span>
          <span className="text-gray-400 text-xs md:text-xs" style={{ marginLeft: '4px', border: '1px solid rgba(255, 254, 239, 0.27)', borderRadius: '4px', padding: '1px 3px' }}>{version}</span>
        </div>
      </div>
      <div className="flex items-center space-x-2 md:space-x-4 mb-4">
        <a href={poolLink} className="text-xs md:text-sm underline cursor-pointer" style={{ color: '#7e22ce' }} target="_blank" rel="noopener noreferrer">Get your LP</a>
        <a href={poolAddress} className="text-xs md:text-sm underline cursor-pointer" style={{ color: '#7e22ce' }} target="_blank" rel="noopener noreferrer">Contract</a>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex flex-col items-center md:items-start space-y-1 md:space-y-2">
          <span className="text-gray-400 text-xs md:text-sm">Total Liquidity:</span>
          <span className="text-white font-bold text-xs md:text-base">{parseFloat(web3.utils.fromWei(totalStaked, 'ether')).toFixed(2)}</span>
          <span className="text-white font-bold text-xs md:text-xs">{token0Details.symbol}-{token1Details.symbol}</span>
        </div>
        <div className="flex flex-col items-center md:items-start space-y-1 md:space-y-2">
          <span className="text-gray-400 text-xs md:text-sm">Your Liquidity:</span>
          <span onClick={handleMaxLiquidity} className="text-white font-bold text-xs md:text-base cursor-pointer underline">
            {parseFloat(web3.utils.fromWei(userInfo.amount, 'ether')).toFixed(2)}
          </span>
          <span className="text-white font-bold text-xs md:text-xs">{token0Details.symbol}-{token1Details.symbol}</span>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex flex-col items-center md:items-start space-y-1 md:space-y-2">
          <span className="text-gray-400 text-xs md:text-sm">Pending Reward:</span>
          <span className="text-white font-bold text-xs md:text-base">{parseFloat(web3.utils.fromWei(pendingReward, 'ether')).toFixed(2)}</span>
          <span className="text-white font-bold text-xs md:text-xs">PLASMA</span>
        </div>
        <div className="flex flex-col items-center md:items-start space-y-1 md:space-y-2">
          <span className="text-gray-400 text-xs md:text-xs">APR:</span>
          <span className="text-white font-bold text-xs md:text-base">{parseFloat(apr).toFixed(2)}%</span>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="text"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
          className="bg-gray-800 p-2 md:p-3 rounded w-full text-white border border-gray-700 focus:border-purple-500 focus:outline-none"
        />
      </div>
      <div onClick={handleMax} className="text-gray-400 text-center md:text-right mb-4 text-xs md:text-sm cursor-pointer underline">
        Available: {web3.utils.fromWei(lpTokenBalance, 'ether')} {farmName} LP
      </div>
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
        <button onClick={handleDeposit} className="bg-purple-700 hover:bg-purple-800 transition duration-300 text-white py-2 px-4 rounded w-full md:w-auto" disabled={loadingDeposit || loadingWithdraw || loadingClaim}>
          {loadingDeposit ? 'Depositing...' : 'Deposit'}
        </button>
        <button onClick={handleWithdraw} className="bg-purple-700 hover:bg-purple-800 transition duration-300 text-white py-2 px-4 rounded w-full md:w-auto" disabled={loadingDeposit || loadingWithdraw || loadingClaim}>
          {loadingWithdraw ? 'Withdrawing...' : 'Withdraw'}
        </button>
        <button onClick={handleClaimRewards} className="bg-purple-700 hover:bg-purple-800 transition duration-300 text-white py-2 px-4 rounded w-full md:w-auto" disabled={loadingDeposit || loadingWithdraw || loadingClaim}>
          {loadingClaim ? 'Claiming...' : 'Claim Rewards'}
        </button>
      </div>
      {message && <p className="mt-4 text-yellow-400 text-center md:text-left">{message}</p>}
    </div>
  );
};

export default Pool;
