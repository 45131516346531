import React, { useState, useEffect, useContext } from 'react';
import { Web3Context } from '../contexts/Web3Context';
import Pool from './Pool';
import BigNumber from 'bignumber.js';

import MasterChefABI from '../abis/MasterChef.json';
// import LPTokenABI from '../abis/LPToken.json';
// import ERC20ABI from '../abis/ERC20.json';

const masterChefAddress = '0xC7Cb86f4F1E7690d2e0bE2Ea737c916a72156C2e';

export default function Pools() {
  const { web3 } = useContext(Web3Context);
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const totalAllocPoints = new BigNumber(1000);

  useEffect(() => {
    const fetchPools = async () => {
      if (web3) {
        const masterChef = new web3.eth.Contract(MasterChefABI, masterChefAddress);
        try {
          const poolLength = await masterChef.methods.poolLength().call();
          const poolPromises = [];
          for (let i = 0; i < poolLength; i++) {
            poolPromises.push(masterChef.methods.poolInfo(i).call());
          }
          const poolData = await Promise.all(poolPromises);
          const response = await fetch('/pools.json');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const metadata = await response.json();
          const mergedData = poolData.map(pool => {
            const meta = metadata.find(m => m.lpToken === pool.lpToken);
            return {
              ...pool,
              ...meta
            };
          });

          setPools(mergedData);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchPools();
  }, [web3]);

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 mt-5">Error fetching pools: {error.message}</div>;
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-3xl font-bold mb-6 text-white">Pools</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {pools.map((pool, index) => {
          const poolLink = `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/add/${pool.version}/${pool.token0Address}/${pool.token1Address}`;
          const poolAddress = `https://scan.9mm.pro/address/${pool.lpToken}`;
          return (
            <Pool
              key={index}
              pool={pool}
              index={index}
              totalAllocPoints={totalAllocPoints.toString()}
              logoUrl0={pool.logoUrl0 || 'images/wpls.png'}
              logoUrl1={pool.logoUrl1 || 'images/wpls.png'}
              poolLink={poolLink}
              poolAddress={poolAddress}
              dex="PulseX"
              version={pool.version}
            />
          );
        })}
      </div>
    </div>
  );
}
